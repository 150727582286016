import { t } from '../translation';

export default function validateMeasurement(this: any) {
  if (this.value === undefined) return t('Measures cannot be empty');
  if (this.value && Array.isArray(this.value)) {
    if (this.value.length < 1) return t('Measures cannot be empty');
    for (let i = 0; i < this.value.length; i += 1) {
      const measure = this.value[i];
      // Check if measure name is valid
      if (
        measure.MeasureName === null ||
        measure.MeasureName === '' ||
        measure.MeasureName === undefined
      )
        return t('Measure name cannot be empty');
      if (measure.MeasureName.includes('"'))
        return t('Measure name cannot include "');
      // Check if measure name is duplicated
      if (measure.ValidName === false)
        return t(
          'Measure name cannot be duplicated, including with saved measures',
        );
      // Check if calculated measure is valid
      if (measure.isCalculatedMeasure) {
        if (
          measure.isValidExpression !== undefined &&
          measure.isValidExpression === false
        )
          return measure?.validationErrors || 'Invalid calculated measure';
      } else {
        if (
          measure.SelectControlAggregator === null ||
          measure.SelectControlAggregator === '' ||
          measure.SelectControlAggregator === undefined
        )
          return t('Aggregator cannot be empty');
        if (
          measure.SelectControlColumn === null ||
          measure.SelectControlColumn === '' ||
          measure.SelectControlColumn === undefined
        )
          return t('Measure column cannot be empty');
        if (
          measure.SelectControlAggregator === 'PERCENTILE' &&
          (measure.SelectControlPercentile === null ||
            measure.SelectControlPercentile === '' ||
            measure.SelectControlPercentile === undefined)
        ) {
          return t('Percentile column cannot be empty');
        }
      }
    }
  }
  return false;
}

/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import rison from 'rison';
import { Dispatch } from 'redux';
import {
  DatasourceType,
  type QueryFormData,
  SimpleAdhocFilter,
  SupersetClient,
  t,
} from '@superset-ui/core';
import { addSuccessToast } from 'src/components/MessageToasts/actions';
import { isEmpty } from 'lodash';
import { Slice } from 'src/dashboard/types';
import { Operators } from '../constants';
import { buildV1ChartDataPayload } from '../exploreUtils';

export interface PayloadSlice extends Slice {
  params: string;
  dashboards: number[];
  query_context: string;
}
const ADHOC_FILTER_REGEX = /^adhoc_filters/;

export const FETCH_DASHBOARDS_SUCCEEDED = 'FETCH_DASHBOARDS_SUCCEEDED';
export function fetchDashboardsSucceeded(choices: string[]) {
  return { type: FETCH_DASHBOARDS_SUCCEEDED, choices };
}

export const FETCH_DASHBOARDS_FAILED = 'FETCH_DASHBOARDS_FAILED';
export function fetchDashboardsFailed(userId: string) {
  return { type: FETCH_DASHBOARDS_FAILED, userId };
}

export const SET_SAVE_CHART_MODAL_VISIBILITY =
  'SET_SAVE_CHART_MODAL_VISIBILITY';
export function setSaveChartModalVisibility(isVisible: boolean) {
  return { type: SET_SAVE_CHART_MODAL_VISIBILITY, isVisible };
}

export const SAVE_SLICE_FAILED = 'SAVE_SLICE_FAILED';
export function saveSliceFailed() {
  return { type: SAVE_SLICE_FAILED };
}

export const SAVE_SLICE_SUCCESS = 'SAVE_SLICE_SUCCESS';
export function saveSliceSuccess(data: Partial<QueryFormData>) {
  return { type: SAVE_SLICE_SUCCESS, data };
}

export const SAVE_MEASURE_FAILED = 'SAVE_MEASURE_FAILED';
export function saveMeasureFailed() {
  return { type: SAVE_MEASURE_FAILED };
}

export const SAVE_MEASURE_SUCCESS = 'SAVE_MEASURE_SUCCESS';
export function saveMeasureSuccess(data?: any) {
  return { type: SAVE_MEASURE_SUCCESS, data };
}

export const SAVE_COHORT_FAILED = 'SAVE_COHORT_FAILED';
export function saveCohortFailed() {
  return { type: SAVE_COHORT_FAILED };
}

export const SAVE_COHORT_SUCCESS = 'SAVE_COHORT_SUCCESS';
export function saveCohortSuccess(data?: any) {
  return { type: SAVE_COHORT_SUCCESS, data };
}

export const SAVE_COLUMN_FAILED = 'SAVE_COLUMN_FAILED';
export function saveColumnFailed(data?: any) {
  return { type: SAVE_COLUMN_FAILED, data };
}

export const SAVE_COLUMN_SUCCESS = 'SAVE_COLUMN_SUCCESS';
export function saveColumnSuccess(data?: any) {
  return { type: SAVE_COLUMN_SUCCESS, data };
}

function extractAdhocFiltersFromFormData(
  formDataToHandle: QueryFormData,
): Partial<QueryFormData> {
  const result: Partial<QueryFormData> = {};
  Object.entries(formDataToHandle).forEach(([key, value]) => {
    if (ADHOC_FILTER_REGEX.test(key) && Array.isArray(value)) {
      result[key] = (value as SimpleAdhocFilter[]).filter(
        (f: SimpleAdhocFilter) => !f.isExtra,
      );
    }
  });
  return result;
}

const hasTemporalRangeFilter = (formData: Partial<QueryFormData>): boolean =>
  (formData?.adhoc_filters || []).some(
    (filter: SimpleAdhocFilter) => filter.operator === Operators.TemporalRange,
  );

// Get cohort top n series from the queryresponse when saving a cohort
export const getCohortTopSeries = (queryResponse: any, dimensions: any) => {
  const result = queryResponse?.data;

  // If the query response or dimension is empty, return []
  if (!dimensions || !result) {
    return [];
  }

  // Filter the result to only include the dimensions
  return result
    .map((item: any) => {
      const filtered: any = {};
      Object.keys(item).forEach(key => {
        if (dimensions.includes(key)) {
          filtered[key] = item[key];
        }
      });
      return filtered;
    })
    .filter((filtered: any) => Object.keys(filtered).length > 0);
};

export const getSlicePayload = (
  sliceName: string,
  formDataWithNativeFilters: QueryFormData = {} as QueryFormData,
  dashboards: number[],
  owners: [],
  formDataFromSlice: QueryFormData = {} as QueryFormData,
  columns: any = [],
): Partial<PayloadSlice> => {
  const adhocFilters: Partial<QueryFormData> = extractAdhocFiltersFromFormData(
    formDataWithNativeFilters,
  );

  if (
    !isEmpty(formDataFromSlice) &&
    formDataWithNativeFilters.adhoc_filters &&
    formDataWithNativeFilters.adhoc_filters.length > 0
  ) {
    Object.keys(adhocFilters).forEach(adhocFilterKey => {
      if (isEmpty(adhocFilters[adhocFilterKey])) {
        const sourceFilters = formDataFromSlice[adhocFilterKey];
        if (Array.isArray(sourceFilters)) {
          const targetArray = adhocFilters[adhocFilterKey] || [];
          sourceFilters.forEach(filter => {
            if (filter.operator === Operators.TemporalRange) {
              targetArray.push({
                ...filter,
                comparator: filter.comparator || 'Last day',
              });
            }
          });
          adhocFilters[adhocFilterKey] = targetArray;
        }
      }
    });
  }

  if (!hasTemporalRangeFilter(adhocFilters)) {
    const adhocFiltersKeys = Object.keys(formDataWithNativeFilters).filter(
      key => ADHOC_FILTER_REGEX.test(key),
    );
    adhocFiltersKeys?.forEach(filtersKey => {
      formDataWithNativeFilters[filtersKey]?.forEach(
        (filter: SimpleAdhocFilter) => {
          if (filter.operator === Operators.TemporalRange && filter.isExtra) {
            if (!adhocFilters[filtersKey]) {
              adhocFilters[filtersKey] = [];
            }
            adhocFilters[filtersKey].push({
              ...filter,
              comparator: 'Last day',
            });
          }
        },
      );
    });
  }
  const formData: any = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      f => !f.isExtra,
    ),
    dashboards,
  };

  const {
    metrics,
    metric,
    measurements,
    adhoc_filters: globalFilters,
    groupby,
  } = formData;
  const savedCols = new Set();
  const savedCohorts = new Set();
  if (metric?.column?.expression) {
    savedCols.add(metric.column.id);
  }
  if (metrics) {
    metrics.forEach((m: any) => {
      if (m.column?.expression) {
        savedCols.add(m.column.id);
      }
    });
  }

  // Saved columns in filters
  if (globalFilters?.length > 0) {
    const colsInFilter = globalFilters[0]?.savedColumns || [];
    colsInFilter.forEach((item: any) => savedCols.add(item));
    const cohortsInFilter = globalFilters[0]?.savedCohorts || [];
    cohortsInFilter.forEach((item: any) => savedCohorts.add(item));
  }

  // Saved columns in metrics
  if (measurements) {
    measurements.forEach((measure: any) => {
      const measureFilter = measure.AdhocFilterControl;
      if (measureFilter && measureFilter.length > 0) {
        const colsInFilter = measureFilter[0]?.savedColumns || [];
        colsInFilter.forEach((item: any) => savedCols.add(item));
        const cohortsInFilter = measureFilter[0]?.savedCohorts || [];
        cohortsInFilter.forEach((item: any) => savedCohorts.add(item));
      }
    });
  }

  // Saved columns in dimensions
  if (groupby?.length > 0 && columns.length > 0) {
    groupby.forEach((item: any) => {
      const col: any = columns.find((c: any) => c.column_name === item);
      if (col?.expression) {
        savedCols.add(col.id);
      }
    });
  }

  savedCols.delete(undefined);
  formData.savedColumns = [...savedCols];
  formData.savedCohorts = [...savedCohorts];

  let datasourceId = 0;
  let datasourceType: DatasourceType = DatasourceType.Table;

  if (formData.datasource) {
    const [id, typeString] = formData.datasource.split('__');
    datasourceId = parseInt(id, 10);

    const formattedTypeString =
      typeString.charAt(0).toUpperCase() + typeString.slice(1);
    if (formattedTypeString in DatasourceType) {
      datasourceType =
        DatasourceType[formattedTypeString as keyof typeof DatasourceType];
    }
  }

  const payload: Partial<PayloadSlice> = {
    params: JSON.stringify(formData),
    slice_name: sliceName,
    viz_type: formData.viz_type,
    datasource_id: datasourceId,
    datasource_type: datasourceType,
    dashboards,
    owners,
    // @ts-ignore
    saved_measures: formData.saved_measures,
    saved_cols: formData.savedColumns,
    saved_cohorts: formData.savedCohorts,
    query_context: JSON.stringify(
      buildV1ChartDataPayload({
        formData,
        force: false,
        resultFormat: 'json',
        resultType: 'full',
        setDataMask: null,
        ownState: null,
      }),
    ),
  };

  return payload;
};

export const getMeasurePayload = (
  measureName: any,
  formDataWithNativeFilters: any,
  owners?: any,
) => {
  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      (f: any) => !f.isExtra,
    ),
  };

  const [datasourceId, datasourceType] = formData.datasource.split('__');
  const payload = {
    params: JSON.stringify(formData),
    measure_name: measureName,
    viz_type: formData.viz_type,
    datasource_id: parseInt(datasourceId, 10),
    datasource_type: datasourceType,
    dashboards: formData.dashboards,
    tags: formData.tags,
    saved_measures: getSavedMeasuresInChart(formData),
    owners,
    query_context: JSON.stringify(
      buildV1ChartDataPayload({
        formData,
        force: false,
        resultFormat: 'json',
        resultType: 'full',
        setDataMask: null,
        ownState: null,
      }),
    ),
  };
  return payload;
};

// Get cohort upodate/create request payload
export const getCohortPayload = (
  cohortName: any,
  formDataWithNativeFilters: any,
  queryResponse: any,
  owners?: any,
) => {
  const dimensions = formDataWithNativeFilters?.groupby;
  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      (f: any) => !f.isExtra,
    ),
  };
  const series = getCohortTopSeries(queryResponse, dimensions);

  const [datasourceId, datasourceType] = formData.datasource.split('__');
  const payload = {
    params: JSON.stringify(formData),
    cohort_name: cohortName,
    viz_type: formData.viz_type,
    datasource_id: parseInt(datasourceId, 10),
    datasource_type: datasourceType,
    dashboards: formData.dashboards,
    tags: formData.tags,
    owners,
    top_series: JSON.stringify(series),
    actor: formData.actor,
    query_context: JSON.stringify(
      buildV1ChartDataPayload({
        formData,
        force: false,
        resultFormat: 'json',
        resultType: 'full',
        setDataMask: null,
        ownState: null,
      }),
    ),
  };
  return payload;
};

export const getSavedMeasuresInChart = (formData: QueryFormData) => {
  const savedMeasures: Set<string> = new Set();

  formData?.measurements?.forEach(
    (measure: { measure_id?: string; measure_ids?: string[] }) => {
      if (measure.measure_id) savedMeasures.add(measure.measure_id);
      if (measure.measure_ids)
        measure.measure_ids.forEach(id => savedMeasures.add(id));
    },
  );

  return Array.from(savedMeasures);
};

export const getSavedColumnPayload = (
  columnName: any,
  formDataWithNativeFilters: any,
  owners: any,
) => {
  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      (f: any) => !f.isExtra,
    ),
  };
  let payload = {};
  if (formData.viz_type === 'saved_column') {
    const expression = formData.groupby[0].sqlExpression;
    const { SavedColumns } = formData;
    const filterable = SavedColumns.isFilterable || false;
    const groupby = SavedColumns.isDimension || false;
    const type = SavedColumns.dataType;
    const { method } = SavedColumns;
    payload = {
      type,
      expression,
      filterable,
      groupby,
      method,
    };
  } else {
    const expression =
      formData.columns[formData.columns.length - 1].sqlExpression;
    const { CalculatedColumns } = formData;
    const filterable = CalculatedColumns.isFilterable || false;
    const groupby = CalculatedColumns.isDimension || false;
    let type = CalculatedColumns.dataType;
    let isDttm = false;
    if (
      [
        'HOUR_OF_DAY',
        'DAY_OF_WEEK',
        'DAY_OF_MONTH',
        'DAY_OF_YEAR',
        'WEEK_OF_YEAR',
        'MONTH_OF_YEAR',
      ].includes(CalculatedColumns.timeOperator)
    ) {
      type = 'NUMERIC';
    }
    if (type === 'DATETIME') {
      isDttm = true;
    }
    const { method } = CalculatedColumns;
    payload = {
      type,
      expression,
      filterable,
      groupby,
      method,
      is_dttm: isDttm,
    };
  }

  const [datasourceId] = formData.datasource.split('__');
  payload = {
    ...payload,
    form_data: JSON.stringify(formData),
    column_name: columnName,
    // viz_type: formData.viz_type,
    table_id: parseInt(datasourceId, 10),
    owners,
  };
  return payload;
};

const addToasts = (
  isNewSlice: boolean,
  sliceName: string,
  addedToDashboard?: {
    title: string;
    new?: boolean;
  },
  chartType?: string,
) => {
  const toasts = [];
  if (isNewSlice) {
    toasts.push(
      addSuccessToast(t('%s [%s] has been saved', chartType, sliceName)),
    );
  } else {
    toasts.push(
      addSuccessToast(t('%s [%s] has been overwritten', chartType, sliceName)),
    );
  }

  if (addedToDashboard) {
    if (addedToDashboard.new) {
      toasts.push(
        addSuccessToast(
          t(
            'Dashboard [%s] just got created and chart [%s] was added to it',
            addedToDashboard.title,
            sliceName,
          ),
        ),
      );
    } else {
      toasts.push(
        addSuccessToast(
          t(
            'Chart [%s] was added to dashboard [%s]',
            sliceName,
            addedToDashboard.title,
          ),
        ),
      );
    }
  }

  return toasts;
};

export const updateSlice =
  (
    slice: Slice,
    sliceName: string,
    dashboards: number[],
    addedToDashboard?: {
      title: string;
      new?: boolean;
    },
    columns?: any[],
  ) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    const { slice_id: sliceId, owners, form_data: formDataFromSlice } = slice;
    const formData = getState().explore?.form_data;
    const savedMeasures = getSavedMeasuresInChart(formData);
    try {
      const response = await SupersetClient.put({
        endpoint: `/api/v1/chart/${sliceId}`,
        jsonPayload: getSlicePayload(
          sliceName,
          { ...formData, saved_measures: savedMeasures },
          dashboards,
          owners as [],
          { ...formDataFromSlice, saved_measures: savedMeasures },
          columns,
        ),
      });

      dispatch(saveSliceSuccess(response.json));
      addToasts(false, sliceName, addedToDashboard, 'Chart').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveSliceFailed());
      throw error;
    }
  };

export const createSlice =
  (
    sliceName: string,
    dashboards: number[],
    addedToDashboard?: {
      title: string;
      new?: boolean;
    },
    columns?: any[],
  ) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    const formData = getState().explore?.form_data;
    const savedMeasures = getSavedMeasuresInChart(formData);
    const newFormData = { ...formData, saved_measures: savedMeasures };
    try {
      const response = await SupersetClient.post({
        endpoint: `/api/v1/chart/`,
        jsonPayload: getSlicePayload(
          sliceName,
          newFormData,
          dashboards,
          [],
          {} as QueryFormData,
          columns,
        ),
      });

      dispatch(saveSliceSuccess(response.json));
      addToasts(true, sliceName, addedToDashboard, 'Chart').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveSliceFailed());
      throw error;
    }
  };
// Create new cohort
export const createCohort =
  (cohortName: any, formData: any, queryResponse: any) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    try {
      const response = await SupersetClient.post({
        endpoint: `/api/v1/cohort/`,
        jsonPayload: getCohortPayload(cohortName, formData, queryResponse),
      });

      dispatch(saveCohortSuccess());
      addToasts(true, cohortName, undefined, 'Cohort').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveCohortFailed());
      throw error;
    }
  };

// update a cohort
export const updateCohort =
  (
    { cohort_id: cohortId, owners }: any,
    cohortName: any,
    formData: any,
    queryResponse: any,
  ) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    try {
      const response = await SupersetClient.put({
        endpoint: `/api/v1/cohort/${cohortId}`,
        jsonPayload: getCohortPayload(
          cohortName,
          formData,
          queryResponse,
          owners,
        ),
      });
      dispatch(saveCohortSuccess());
      addToasts(false, cohortName, undefined, 'Cohort').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveCohortFailed());
      throw error;
    }
  };

export const createDashboard =
  (dashboardName: string) => async (dispatch: Dispatch) => {
    try {
      const response = await SupersetClient.post({
        endpoint: `/api/v1/dashboard/`,
        jsonPayload: {
          dashboard_title: dashboardName,
          // Dashboards with empty json_metadata will be hidden on the dashboard list
          // For the dashboard created from chart, add default value to json_metadata to make it visible
          json_metadata: '{"color_scheme":""}',
        },
      });

      return response.json;
    } catch (error) {
      dispatch(saveSliceFailed());
      throw error;
    }
  };

export const updateMeasure =
  ({ measure_id: measureId, owners }: any, measureName: any, formData: any) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    const tags = formData.measurements[0].tags || [];
    const newFormData = {
      ...formData,
      tags,
    };
    try {
      const response = await SupersetClient.put({
        endpoint: `/api/v1/measure/${measureId}`,
        jsonPayload: getMeasurePayload(measureName, newFormData, owners),
      });
      dispatch(saveMeasureSuccess());
      addToasts(false, measureName, undefined, 'Measure').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveMeasureFailed());
      throw error;
    }
  };

// Create new measure
export const createMeasure =
  (measureName: any, formData: any) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    const tags = formData.measurements[0].tags || [];
    const newFormData = {
      ...formData,
      tags,
    };
    try {
      const response = await SupersetClient.post({
        endpoint: `/api/v1/measure/`,
        jsonPayload: getMeasurePayload(measureName, newFormData),
      });

      dispatch(saveMeasureSuccess());
      addToasts(true, measureName, undefined, 'Measure').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveMeasureFailed());
      throw error;
    }
  };

// Create new column
export const createColumn =
  (columnName: any, formData: any, owners?: any) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    try {
      const response = await SupersetClient.post({
        endpoint: `/api/v1/dataset/saved_column/`,
        jsonPayload: getSavedColumnPayload(columnName, formData, owners),
      });

      dispatch(saveColumnSuccess());
      addToasts(true, columnName, undefined, 'Column').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveColumnFailed());
      throw error;
    }
  };

// Update existing column
export const updateColumn =
  (savedColumn: any, columnName: any, formData: any) =>
  async (dispatch: Dispatch, getState: () => Partial<QueryFormData>) => {
    const { owners, id } = savedColumn;
    const formatOwners = owners.map((owner: any) => owner.id);
    try {
      const response = await SupersetClient.put({
        endpoint: `/api/v1/dataset/saved_column/${id}/`,
        jsonPayload: getSavedColumnPayload(columnName, formData, formatOwners),
      });
      dispatch(saveColumnSuccess());
      addToasts(false, columnName, undefined, 'Column').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveColumnFailed());
      throw error;
    }
  };

export const getSliceDashboards =
  (slice: Partial<Slice>) => async (dispatch: Dispatch) => {
    try {
      const response = await SupersetClient.get({
        endpoint: `/api/v1/chart/${slice.slice_id}?q=${rison.encode({
          columns: ['dashboards.id'],
        })}`,
      });

      return response.json.result.dashboards.map(
        ({ id }: { id: number }) => id,
      );
    } catch (error) {
      dispatch(saveSliceFailed());
      throw error;
    }
  };

import { ensureIsArray } from '@superset-ui/core';
import { Operators, UUID_REGEX } from 'src/explore/constants';
import { useState } from 'react';
import AdvancedFilterGroup from '../AdvancedAdhocMetricFilter/AdvancedFilterGroup';
import { IAdvancedMetricFilter } from '../AdvancedAdhocMetricFilter/AdvancedMetricFilter.type';
import AdvancedMetricFilterGroup, {
  generateFilterKey,
  FilterGroupOperator,
} from '../AdvancedAdhocMetricFilter/AdvancedMetricFilterGroup';
import AdhocFilter from '../FilterControl/AdhocFilter';
import { translateToSql } from '../FilterControl/utils/translateToSQL';
import { Clauses } from '../FilterControl/types';
import AdhocMetric, { EXPRESSION_TYPES } from '../MetricControl/AdhocMetric';
import { HeaderContainer, LabelsContainer } from '../OptionControls';
import AdvancedFilterPopoverTrigger from './AdvancedFilterPopoverTrigger';

export interface IAdvancedMetricFilterControlProps {
  disabled?: boolean;
  columns: any[];
  datasource: object;
  operators?: Operators[];
  sections: string[];
  onChange: Function;
  selectedMetrics: object;
  isFromMetrics?: boolean;
  value?: AdhocFilter[];
  sqlStatement?: string;
  name?: string;
  label?: string;
  description?: string;
  viz_type?: string;
  cohorts?: any[];
}

export default function AdvancedMetricFilterControl(
  props: IAdvancedMetricFilterControlProps,
): JSX.Element {
  const [filterGroup, setFilterGroup] = useState<AdvancedMetricFilterGroup>(
    () => {
      if (props.sqlStatement) {
        return convertSqlToAdvancedFilterGroup(props.sqlStatement);
      }
      if (props.value?.length === 1) {
        const { filterGroup, sqlExpression } = props.value[0];
        // If filterGrop exists, get filter group info from filterGroup and columns
        // Else, parse sqlExpression and get filterGroup
        if (filterGroup) {
          try {
            return jsonToFilterGroup(
              filterGroup,
              props.columns,
              props.cohorts || [],
            );
          } catch (error) {
            return convertSqlToAdvancedFilterGroup(sqlExpression);
          }
        }
        return convertSqlToAdvancedFilterGroup(sqlExpression);
      }
      return new AdvancedMetricFilterGroup(undefined);
    },
  );
  const [selectedFilter, setSelectedFilter] = useState<IAdvancedMetricFilter>();
  const [selectedFilterGroup, setSelectedFilterGroup] =
    useState<AdvancedMetricFilterGroup>(filterGroup);

  function removeFilter(filter: IAdvancedMetricFilter) {
    filter.container.removeFilter(filter);
    setFilterGroup(filterGroup);
    triggerChange();
  }

  function triggerChange() {
    props.onChange(convertFilterGroupToAdhocFilter(filterGroup));
  }

  function onFilterEdit(changedFilter: AdhocFilter) {
    if (selectedFilter) {
      selectedFilter.adhocFilter = changedFilter;
      setSelectedFilter(selectedFilter);
    }
    setFilterGroup(filterGroup);
    triggerChange();
  }

  function optionsForSelect(): any[] {
    const options = [
      ...props.columns,
      ...ensureIsArray(props.selectedMetrics).map(
        metric =>
          metric &&
          (typeof metric === 'string'
            ? { saved_metric_name: metric }
            : new AdhocMetric(metric)),
      ),
    ].filter(option => option);

    return options
      .reduce((results, option) => {
        if (option.saved_metric_name) {
          results.push({
            ...option,
            filterOptionName: option.saved_metric_name,
          });
        } else if (option.column_name) {
          results.push({
            ...option,
            filterOptionName: `_col_${option.column_name}`,
          });
        } else if (option instanceof AdhocMetric) {
          results.push({
            ...option,
            filterOptionName: `_adhocmetric_${option.label}`,
          });
        }
        return results;
      }, [])
      .sort((a: any, b: any) =>
        (a.saved_metric_name || a.column_name || a.label).localeCompare(
          b.saved_metric_name || b.column_name || b.label,
        ),
      );
  }

  return (
    <div data-test="adhoc-filter-control">
      <HeaderContainer>
        <AdvancedFilterPopoverTrigger
          {...props}
          filterGroup={filterGroup}
          selectedFilter={selectedFilter}
          selectedFilterGroup={selectedFilterGroup}
          triggerChange={triggerChange}
          onSelectFilter={setSelectedFilter}
          onSelectFilterGroup={setSelectedFilterGroup}
        />
      </HeaderContainer>
      <LabelsContainer>
        <AdvancedFilterGroup
          filterGroup={filterGroup}
          selectedFilter={selectedFilter}
          onSelectFilter={setSelectedFilter}
          onSelectGroup={setSelectedFilterGroup}
          triggerChange={triggerChange}
          onRemove={props.disabled ? () => undefined : removeFilter}
          isFromMetrics={props.isFromMetrics}
          sections={props.sections}
          datasource={props.datasource}
          operators={props.operators}
          onFilterEdit={onFilterEdit}
          options={optionsForSelect()}
          disabled={props.disabled}
          viz_type={props.viz_type}
        />
      </LabelsContainer>
    </div>
  );
}

export function convertFilterGroupToAdhocFilter(
  filteGroup: AdvancedMetricFilterGroup,
): AdhocFilter[] {
  const sqlStatement: string = translateToSqlQuery(filteGroup);
  if (!sqlStatement) {
    return [];
  }
  const adhocFilter = new AdhocFilter({
    expressionType: 'SQL',
    clause: 'WHERE',
  });
  adhocFilter.sqlExpression = sqlStatement;
  adhocFilter.filterGroup = filterGroupToJson(filteGroup);
  adhocFilter.savedColumns = getSavedColumns(filteGroup);
  adhocFilter.savedCohorts = getSavedCohorts(filteGroup);
  adhocFilter.customSqls = getCustomSqls(filteGroup);
  return [adhocFilter];
}

export function filterGroupToJson(filterGroup: AdvancedMetricFilterGroup): any {
  if (!filterGroup) {
    return undefined;
  }
  return {
    operator: filterGroup.operator,
    filters: filterGroup?.filters.map(filter => filter.adhocFilter),
    groups: filterGroup?.groups.map((group: AdvancedMetricFilterGroup) =>
      filterGroupToJson(group),
    ),
  };
}

function getItemsFromFilterGroup(
  filterGroup: AdvancedMetricFilterGroup,
  conditionFn: (adhocFilter: any) => boolean,
  getValueFn: (adhocFilter: any) => any,
): any[] {
  if (!filterGroup) return [];

  const resultSet = new Set();
  filterGroup.filters.forEach(filter => {
    const { adhocFilter } = filter;
    if (conditionFn(adhocFilter)) {
      resultSet.add(getValueFn(adhocFilter));
    }
  });

  const nestedItems = filterGroup.groups.flatMap(group =>
    getItemsFromFilterGroup(group, conditionFn, getValueFn),
  );

  return [...resultSet, ...nestedItems];
}

// Get saved columns
function getSavedColumns(filterGroup: AdvancedMetricFilterGroup): any[] {
  return getItemsFromFilterGroup(
    filterGroup,
    adhocFilter => !!adhocFilter?.column?.expression,
    adhocFilter => adhocFilter.column.id,
  );
}

// Get custom SQLs for sub query check
function getCustomSqls(filterGroup: AdvancedMetricFilterGroup): any[] {
  return getItemsFromFilterGroup(
    filterGroup,
    adhocFilter =>
      adhocFilter?.currentEditTab === 'SQL' && !!adhocFilter?.sqlExpression,
    adhocFilter => adhocFilter.sqlExpression,
  );
}

// Get saved cohorts
function getSavedCohorts(filterGroup: AdvancedMetricFilterGroup): any[] {
  return getItemsFromFilterGroup(
    filterGroup,
    adhocFilter =>
      adhocFilter?.currentEditTab === 'COHORT' && !!adhocFilter?.cohort?.id,
    adhocFilter => adhocFilter.cohort.id,
  );
}

// Convert json to filter group
export function jsonToFilterGroup(
  filterGroupJson: any,
  columns: any[],
  cohorts: any[],
): any {
  if (!filterGroupJson) {
    return undefined;
  }
  const filterGroup: AdvancedMetricFilterGroup = new AdvancedMetricFilterGroup(
    undefined,
  );
  filterGroup.operator = filterGroupJson.operator;

  filterGroupJson?.filters?.forEach((filter: any) => {
    const { cohort, column, currentEditTab, ...rest } = filter;
    // Cohort filter
    if (currentEditTab === 'COHORT' && cohort?.id) {
      // Get the latest cohort info from the saved cohort list
      const newCohort = cohorts.find(c => c.id === filter.cohort.id);

      if (!newCohort) {
        throw new Error('Invalid cohort id');
      }

      const addfilter = new AdhocFilter({
        ...rest,
        cohortName: newCohort.cohort_name,
        currentEditTab,
        cohort: newCohort,
        cohortTopSeries: newCohort.cohortTopSeries,
      });

      filterGroup.addFilter({
        key: generateFilterKey(),
        adhocFilter: addfilter,
        container: filterGroup,
      });
    }

    // Simple filter
    else if (column?.id && rest.operatorId && rest.operator) {
      const column = columns.find(column => column.id === filter.column.id);
      if (!column) {
        throw new Error('Invalid column id');
      }
      const addfilter = new AdhocFilter({
        subject: column.column_name,
        column,
        ...rest,
      });
      filterGroup.addFilter({
        key: generateFilterKey(),
        adhocFilter: addfilter,
        container: filterGroup,
      });
    }

    // Custome SQL filter
    else if (rest.sqlExpression) {
      const addfilter = new AdhocFilter({
        ...rest,
      });
      filterGroup.addFilter({
        key: generateFilterKey(),
        adhocFilter: addfilter,
        container: filterGroup,
      });
    } else {
      throw new Error('Invalid filter');
    }
  });

  filterGroupJson?.groups?.forEach((group: any) => {
    const addgroup = jsonToFilterGroup(group, columns, cohorts);
    if (
      !addgroup.filters?.filter(Boolean).length &&
      !addgroup.groups?.filter(Boolean).length
    ) {
      return;
    }
    filterGroup.addFilterGroups(jsonToFilterGroup(group, columns, cohorts));
  });

  return filterGroup;
}

export function translateToSqlQuery(
  filteGroup: AdvancedMetricFilterGroup,
): string {
  if (!filteGroup) {
    return '';
  }
  const sql: string = translateGroupsToSql(filteGroup);
  return sql === '' ? sql : sql.substring(1, sql.length - 1);
}

function translateGroupsToSql(filteGroup: AdvancedMetricFilterGroup): string {
  const allFilterSql: string = translateFilterToSql(filteGroup);
  if (!filteGroup || filteGroup.groups.length === 0) {
    return `(${allFilterSql})`;
  }

  const allGroupsSql: string[] = filteGroup.groups.map(group =>
    translateGroupsToSql(group),
  );

  if (allGroupsSql.length === 1) {
    return allFilterSql === ''
      ? `(${allGroupsSql[0]})`
      : `(${allGroupsSql[0]} ${filteGroup.operator} ${allFilterSql})`;
  }
  return allFilterSql === ''
    ? `(${allGroupsSql.join(` ${filteGroup.operator} `)})`
    : `(${allGroupsSql.join(` ${filteGroup.operator} `)} ${
        filteGroup.operator
      } ${allFilterSql})`;
}

function translateFilterToSql(filteGroup: AdvancedMetricFilterGroup): string {
  const allFiltersSql: string[] =
    filteGroup?.filters.map(filter =>
      !filter.adhocFilter ? '' : translateToSql(filter.adhocFilter),
    ) || [];

  if (allFiltersSql.length === 0) {
    return '';
  }
  if (allFiltersSql.length === 1) {
    return `${allFiltersSql[0]}`;
  }
  return `${allFiltersSql.join(` ${filteGroup.operator} `)}`;
}

export function convertSqlToAdvancedFilterGroup(
  sql: string,
): AdvancedMetricFilterGroup {
  const sqlStatement = `(${sql})`;
  const sqlExpressionStack: string[] = [];
  const filterGroupDictionary: { [key: string]: AdvancedMetricFilterGroup } =
    {};
  for (let index = 0; index < sqlStatement.length; index += 1) {
    const eachChar = sqlStatement[index];
    if (eachChar === ')') {
      let currSql = '';
      while (
        sqlExpressionStack.length > 0 &&
        sqlExpressionStack.at(-1) !== '('
      ) {
        currSql = sqlExpressionStack.pop() + currSql;
      }
      sqlExpressionStack.pop();
      if (!currSql.includes('AND') && !currSql.includes('OR')) {
        if (sqlExpressionStack.length > 4) {
          const inOpr = sqlExpressionStack.slice(-4).join('');
          if (inOpr === ' IN ') {
            /* eslint-disable */
            currSql = '(' + currSql + ')';
          }
        }
        sqlExpressionStack.push(currSql);
      } else {
        const filterGroup: AdvancedMetricFilterGroup = convertSqlToGroup(
          currSql,
          filterGroupDictionary,
        );
        filterGroupDictionary[filterGroup.key] = filterGroup;
        sqlExpressionStack.push(filterGroup.key);
      }
    } else {
      sqlExpressionStack.push(eachChar);
    }
  }
  if (sqlExpressionStack[0] && !filterGroupDictionary[sqlExpressionStack[0]]) {
    const newFilterGroup: AdvancedMetricFilterGroup =
      new AdvancedMetricFilterGroup(undefined);
    newFilterGroup.addFilter(
      convertSqlToFilter(
        sqlExpressionStack[0],
        newFilterGroup,
        filterGroupDictionary,
      ),
    );
    return newFilterGroup;
  }
  return filterGroupDictionary[sqlExpressionStack[0]];
}

function convertSqlToGroup(
  sqlStatement: string,
  filterGroupDictionary: any,
): AdvancedMetricFilterGroup {
  const operator: FilterGroupOperator = sqlStatement.includes('AND')
    ? 'AND'
    : 'OR';
  const sqlStatments: string[] = sqlStatement.split(operator);
  const newFilterGroup: AdvancedMetricFilterGroup =
    new AdvancedMetricFilterGroup(undefined);
  newFilterGroup.operator = operator;
  const filterGroups: AdvancedMetricFilterGroup[] = [];
  const filters: IAdvancedMetricFilter[] = [];

  sqlStatments.forEach(curSql => {
    const sql = curSql.trim();
    if (uuidValidate(sql)) {
      /* eslint-disable-next-line no-param-reassign */
      filterGroupDictionary[sql].parent = newFilterGroup;
      filterGroups.push(filterGroupDictionary[sql]);
    } else {
      filters.push(
        convertSqlToFilter(sql, newFilterGroup, filterGroupDictionary),
      );
    }
  });
  filterGroups.forEach(group => newFilterGroup.addFilterGroups(group));
  filters.forEach(filter => newFilterGroup.addFilter(filter));
  return newFilterGroup;
}

function convertSqlToFilter(
  sqlStatement: string,
  filterGroup: AdvancedMetricFilterGroup,
  filterGroupDictionary?: any,
): IAdvancedMetricFilter {
  let newSqlStatement = sqlStatement;
  const regex = /multiIf([a-f\d-]+)/;
  if (sqlStatement.includes('multiIf') && filterGroupDictionary) {
    const filterKey = sqlStatement.match(regex)?.[1];
    if (filterKey) {
      const sql = translateGroupsToSql(filterGroupDictionary[filterKey]);
      newSqlStatement = sqlStatement.replace(regex, `multiIf${sql}`);
    }
  }
  const adhocFilter: AdhocFilter = new AdhocFilter({
    expressionType: EXPRESSION_TYPES.SQL,
    clause: Clauses.Where,
    sqlExpression: newSqlStatement,
  });
  return {
    key: generateFilterKey(),
    adhocFilter,
    container: filterGroup,
  };
}

function uuidValidate(uuid: string): boolean {
  return UUID_REGEX.test(uuid);
}

/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import {
  FC,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';

import FormItem from 'src/components/Form/FormItem';
import { Select, AutoComplete, AntdInput } from 'src/components';
import {
  styled,
  SupersetClient,
  SupersetTheme,
  t,
  JsonObject,
} from '@superset-ui/core';
import {
  Operators,
  OPERATORS_OPTIONS,
  HAVING_OPERATORS,
  MULTI_OPERATORS,
  CUSTOM_OPERATORS,
  DISABLE_INPUT_OPERATORS,
  AGGREGATES,
  OPERATOR_ENUM_TO_OPERATOR_TYPE,
} from 'src/explore/constants';
import FilterDefinitionOption from 'src/explore/components/controls/MetricControl/FilterDefinitionOption';
import AdhocFilter from 'src/explore/components/controls/FilterControl/AdhocFilter';
import {
  ColumnMeta,
  Dataset,
  isTemporalColumn,
} from '@superset-ui/chart-controls';
import { debounce, isArray } from 'lodash';
import { notification } from 'antd';
import { NezhaTelemetry } from 'src/telemetry/helper/CommonTelemetryHelper';
import { NEZHA_OPERATION } from 'src/telemetry/constants/CommonEventsContants';
import { getPathname, parseScenarioFromUri } from 'src/telemetry/util';
import { analytics } from 'src/telemetry/setup';
import useAdvancedDataTypes from './useAdvancedDataTypes';
import { useDefaultTimeFilter } from '../../DateFilterControl/utils';
import { Clauses, ExpressionTypes } from '../types';
import { cacheExistCheck } from '../utils/api';

// [Commented by Nezha]
// const StyledInput = styled(Input)`
//   margin-bottom: ${({ theme }) => theme.gridUnit * 4}px;
// `;

export const StyledFormItem = styled(FormItem)`
  &.ant-row.ant-form-item {
    margin: 0;
  }
`;

// [Commented by Nezha]
// const SelectWithLabel = styled(Select)<{ labelText: string }>`
//   .ant-select-selector::after {
//     content: ${({ labelText }) => labelText || '\\A0'};
//     display: inline-block;
//     white-space: nowrap;
//     color: ${({ theme }) => theme.colors.grayscale.light1};
//     width: max-content;
//   }
// `;

export interface SimpleExpressionType {
  expressionType: keyof typeof ExpressionTypes;
  column: ColumnMeta;
  aggregate: keyof typeof AGGREGATES;
  label: string;
}
export interface SQLExpressionType {
  expressionType: keyof typeof ExpressionTypes;
  sqlExpression: string;
  label: string;
}

export interface MetricColumnType {
  saved_metric_name: string;
}

export interface CohortType {
  cohort_name: string;
  label?: string;
}

export type ColumnType =
  | ColumnMeta
  | SimpleExpressionType
  | SQLExpressionType
  | MetricColumnType
  | CohortType;

export interface Props {
  adhocFilter: AdhocFilter;
  onChange: (filter: AdhocFilter) => void;
  options: ColumnType[];
  datasource: Dataset;
  partitionColumn: string;
  operators?: Operators[];
  validHandler: (isValid: boolean) => void;
  disableFetchFromCache?: boolean;
}

export interface AdvancedDataTypesState {
  parsedAdvancedDataType: string;
  advancedDataTypeOperatorList: string[];
  errorMessage: string;
}

interface InValue {
  value: string | number | boolean | null;
  label: string;
}

// Use this as the cached OperatorComparatorMap
export type CachedOperatorComparatorMap = Record<string, any[] | any>;
// Use this to generate the key of the CachedOperatorComparatorMap, the key of the Record only can be the string|number|symbol
// So we should use a function to generate the key
const generateKeyForCachedOperatorComparator = (
  subject: string,
  operator: Operators,
) => [subject, operator].join('|');

export const initCachedOperatorComparatorMap = (
  subject: string,
  operatorId: Operators,
  comparator: any,
): CachedOperatorComparatorMap => {
  const map: CachedOperatorComparatorMap = {};

  if (!comparator) {
    return map;
  }
  // [Attention]: Please keep each type check logic in a single block to make it easy to maintain and easy to read.

  // Handle array
  if (Array.isArray(comparator) && comparator.length > 0) {
    map[generateKeyForCachedOperatorComparator(subject, operatorId)] =
      comparator;
    return map;
  }

  // handle string, the number will also be converted to string
  if (typeof comparator === 'string' && comparator.length > 0) {
    map[generateKeyForCachedOperatorComparator(subject, operatorId)] =
      comparator;
    return map;
  }

  // other type don't need to support
  return map;
};

export const useSimpleTabFilterProps = (props: Props) => {
  const defaultTimeFilter = useDefaultTimeFilter();

  const isOperatorRelevant = (operator: Operators, subject: string) => {
    const column = props.datasource.columns?.find(
      col => col.column_name === subject,
    );
    const isColumnBoolean =
      !!column &&
      (column.type === 'BOOL' ||
        column.type === 'BOOLEAN' ||
        column.type === 'INT8');
    const isColumnNumber =
      !!column && (column.type === 'INT' || column.type === 'INTEGER');

    if (operator && operator === Operators.LatestPartition) {
      const { partitionColumn } = props;
      return partitionColumn && subject && subject === partitionColumn;
    }
    if (operator && operator === Operators.TemporalRange) {
      // hide the TEMPORAL_RANGE operator
      return false;
    }
    if (
      operator === Operators.IsTrue ||
      operator === Operators.IsFalse ||
      // For bollean type column, only show 'Is true', 'Is false', 'Is Empty, 'Is not Empty'
      operator === Operators.IsBooleanEmpty ||
      operator === Operators.IsBooleanNotEmpty
    ) {
      return isColumnBoolean || isColumnNumber;
    }
    if (isColumnBoolean) {
      return false;
    }
    return (
      props.adhocFilter.clause !== Clauses.Having ||
      HAVING_OPERATORS.indexOf(operator) !== -1
    );
  };

  const defaultSimpleFilter = {
    cohortSubject: undefined,
    cohort: undefined,
    cohortOperator: undefined,
    cohortOperatorId: undefined,
    cohortComparator: undefined,
    currentEditTab: 'SIMPLE',
  };

  const updateCachedOperatorComparatorMap = (
    subject: string,
    operatorId: Operators,
    comparator: any,
    cachedOperatorComparatorMap: CachedOperatorComparatorMap,
    setCachedOperatorComparatorMap: Dispatch<
      SetStateAction<CachedOperatorComparatorMap>
    >,
  ) => {
    const cachedKey = generateKeyForCachedOperatorComparator(
      subject,
      operatorId,
    );
    const newCachedOperatorComparatorMap = { ...cachedOperatorComparatorMap };
    newCachedOperatorComparatorMap[cachedKey] = comparator;
    setCachedOperatorComparatorMap(newCachedOperatorComparatorMap);
  };

  const getCachedOperatorComparator = (
    subject: string,
    operatorId: Operators,
    cachedOperatorComparatorMap: CachedOperatorComparatorMap,
  ) => {
    const cachedKey = generateKeyForCachedOperatorComparator(
      subject,
      operatorId,
    );
    return cachedOperatorComparatorMap[cachedKey];
  };

  const onSubjectChange = (
    id: string,
    cachedOperatorComparatorMap: CachedOperatorComparatorMap,
  ) => {
    const option = props.options.find(
      option =>
        ('column_name' in option && option.column_name === id) ||
        ('optionName' in option && option.optionName === id),
    );
    let subject = '';
    let clause;
    // infer the new clause based on what subject was selected.
    if (option && 'column_name' in option) {
      subject = option.column_name;
      clause = Clauses.Where;
    } else if (option && 'saved_metric_name' in option) {
      subject = option.saved_metric_name;
      clause = Clauses.Having;
    } else if (option?.label) {
      subject = option.label;
      clause = Clauses.Having;
    }
    let { operator, operatorId, comparator } = props.adhocFilter;
    operator =
      operator && operatorId && isOperatorRelevant(operatorId, subject)
        ? OPERATOR_ENUM_TO_OPERATOR_TYPE[operatorId].operation
        : null;

    // [Nezha] commented
    // if (!isDefined(operator)) {
    //   // if operator is `null`, use the `IN` and reset the comparator.
    //   operator = Operators.In;
    //   operatorId = Operators.In;
    //   comparator = undefined;
    // }

    if (isTemporalColumn(id, props.datasource)) {
      subject = id;
      operator = Operators.TemporalRange;
      operatorId = Operators.TemporalRange;
      comparator = defaultTimeFilter;
    }

    const cachedOperatorComparator = getCachedOperatorComparator(
      subject,
      operatorId,
      cachedOperatorComparatorMap,
    );
    if (!cachedOperatorComparator) {
      if (MULTI_OPERATORS.has(operatorId)) {
        comparator = [];
      } else {
        comparator = undefined;
      }
    } else {
      comparator = cachedOperatorComparator;
    }

    const newAdhocFilter = props.adhocFilter.duplicateWith({
      subject,
      clause,
      operator,
      operatorId,
      comparator,
      expressionType: ExpressionTypes.Simple,
      column: option,
      ...defaultSimpleFilter,
    });
    newAdhocFilter.comparator = comparator;
    props.onChange(newAdhocFilter);
  };

  const onOperatorChange = (
    operatorId: Operators,
    cachedOperatorComparatorMap: CachedOperatorComparatorMap,
    setCachedOperatorComparatorMap: Dispatch<
      SetStateAction<CachedOperatorComparatorMap>
    >,
  ) => {
    const currentComparator = props.adhocFilter.comparator;
    let newComparator;
    // convert between list of comparators and individual comparators
    // (e.g. `in ('North America', 'Africa')` to `== 'North America'`)
    if (MULTI_OPERATORS.has(operatorId)) {
      // check is the cache exists
      // get cached subject operatorId <-> comparator from cachedOperatorComparatorMap
      const cachedComparator = getCachedOperatorComparator(
        props.adhocFilter.subject,
        operatorId,
        cachedOperatorComparatorMap,
      );
      // if cached comparator is not exist, use the default logic to convert the currentComparator to a array based comparator
      if (!cachedComparator) {
        newComparator = Array.isArray(currentComparator)
          ? currentComparator
          : [currentComparator].filter(element => element);
      } else {
        newComparator = cachedComparator;
      }
    } else {
      newComparator = Array.isArray(currentComparator)
        ? currentComparator[0]
        : currentComparator;
    }

    // Handle boolean operators to overwrite the comparator value of 1 or 0
    if (operatorId === Operators.IsTrue || operatorId === Operators.IsFalse) {
      newComparator = Operators.IsTrue === operatorId ? 1 : 0;
    }

    // Handle the boolean empty operators to overwrite comparator value
    if (
      operatorId === Operators.IsBooleanEmpty ||
      operatorId === Operators.IsBooleanNotEmpty
    ) {
      newComparator = undefined;
    }

    if (operatorId && CUSTOM_OPERATORS.has(operatorId)) {
      // Handle the customised operators
      props.onChange(
        props.adhocFilter.duplicateWith({
          subject: props.adhocFilter.subject,
          clause: Clauses.Where,
          operatorId,
          operator: OPERATOR_ENUM_TO_OPERATOR_TYPE[operatorId].operation,
          expressionType: ExpressionTypes.Sql,
          datasource: props.datasource,
          ...defaultSimpleFilter,
        }),
      );
    } else {
      updateCachedOperatorComparatorMap(
        props.adhocFilter.subject,
        operatorId,
        newComparator,
        cachedOperatorComparatorMap,
        setCachedOperatorComparatorMap,
      );
      props.onChange(
        props.adhocFilter.duplicateWith({
          operatorId,
          operator: OPERATOR_ENUM_TO_OPERATOR_TYPE[operatorId].operation,
          comparator: newComparator,
          expressionType: ExpressionTypes.Simple,
          ...defaultSimpleFilter,
        }),
      );
    }
  };
  const onComparatorChange = (
    comparator: any,
    cachedOperatorComparatorMap: CachedOperatorComparatorMap,
    setCachedOperatorComparatorMap: Dispatch<
      SetStateAction<CachedOperatorComparatorMap>
    >,
  ) => {
    updateCachedOperatorComparatorMap(
      props.adhocFilter.subject,
      props.adhocFilter.operatorId,
      comparator,
      cachedOperatorComparatorMap,
      setCachedOperatorComparatorMap,
    );
    props.onChange(
      props.adhocFilter.duplicateWith({
        comparator,
        expressionType: ExpressionTypes.Simple,
        ...defaultSimpleFilter,
      }),
    );
  };
  const clearOperator = (): void => {
    props.onChange(
      props.adhocFilter.duplicateWith({
        operatorId: undefined,
        operator: undefined,
        ...defaultSimpleFilter,
      }),
    );
  };
  // const onDatePickerChange = (columnName: string, timeRange: string) => {
  //   props.onChange(
  //     props.adhocFilter.duplicateWith({
  //       subject: columnName,
  //       operator: Operators.TemporalRange,
  //       comparator: timeRange,
  //       expressionType: ExpressionTypes.Simple,
  //     }),
  //   );
  // };

  const isTest = '0';
  const setAutocompleteUrl = (
    str: string,
    type: string,
    id: number,
    col: any,
    isCaseSensitive = 0,
  ): string => {
    if (str && str.length > 0) {
      return `/nezha/filter/${type}/${id}/${col}/${btoa(
        str,
      )}/${isCaseSensitive}/${isTest}/`;
    }
    return `/nezha/filter/${type}/${id}/${col}/${isCaseSensitive}/${isTest}/`;
  };
  const getAutocompleteCacheState = (json: any): string => json.state;

  return {
    onSubjectChange,
    onOperatorChange,
    onComparatorChange,
    isOperatorRelevant,
    clearOperator,
    // onDatePickerChange,
    setAutocompleteUrl,
    getAutocompleteCacheState,
  };
};

const AdhocFilterEditPopoverSimpleTabContent: FC<Props> = props => {
  // const analytics = useContext(AnalyticsContext);

  const {
    onSubjectChange,
    onOperatorChange,
    isOperatorRelevant,
    onComparatorChange,
    // onDatePickerChange,
    clearOperator,
    setAutocompleteUrl,
    getAutocompleteCacheState,
  } = useSimpleTabFilterProps(props);
  const [suggestions, setSuggestions] = useState<Record<string, any>>([]);

  const [firstFocusInputComparator, setFirstFocusInputComparator] =
    useState(false);

  const fetchRef = useRef(0);
  const defaultDelay = 350;
  const [debounceDelay, setDebounceDelay] = useState(defaultDelay);

  // the basic logic Provided by a diccussion with Catherine.
  // according to the data card to ajust the debounce, this can help reduce the large redis set request count
  // the debounce will from 350 to 350 + 350 milliseconds
  const buildDebounceDelay = (value: number) => {
    const minValue = 10000.0;
    const maxValue = 3000000;
    return (
      defaultDelay +
      Math.min(Math.round((250 * value) / (maxValue - minValue)), 350)
    );
  };

  const {
    advancedDataTypesState,
    subjectAdvancedDataType,
    fetchAdvancedDataTypeValueCallback,
    fetchSubjectAdvancedDataType,
  } = useAdvancedDataTypes(props.validHandler);
  // TODO: This does not need to exist, just use the advancedTypeOperatorList list
  const isOperatorRelevantWrapper = (operator: Operators, subject: string) =>
    subjectAdvancedDataType
      ? isOperatorRelevant(operator, subject) &&
        advancedDataTypesState.advancedDataTypeOperatorList.includes(operator)
      : isOperatorRelevant(operator, subject);

  const renderSubjectOptionLabel = (option: any) => (
    <FilterDefinitionOption option={option} />
  );

  // [Commented by Nezha]
  // const getOptionsRemaining = () => {
  //   // if select is multi/value is array, we show the options not selected
  //   const valuesFromSuggestionsLength = Array.isArray(comparator)
  //     ? comparator.filter(v => suggestions.includes(v)).length
  //     : 0;
  //   return suggestions?.length - valuesFromSuggestionsLength ?? 0;
  // };
  // const createSuggestionsPlaceholder = () => {
  //   const optionsRemaining = getOptionsRemaining();
  //   const placeholder = t('%s option(s)', optionsRemaining);
  //   return optionsRemaining ? placeholder : '';
  // };

  // const handleSubjectChange = (subject: string) => {
  //   setComparator(undefined);
  //   onSubjectChange(subject);
  // };

  let columns = props.options;
  const { subject, operator, operatorId } = props.adhocFilter;
  const [cachedOperatorComparatorMap, setCachedOperatorComparatorMap] =
    useState<CachedOperatorComparatorMap>(
      initCachedOperatorComparatorMap(
        subject,
        operatorId,
        props.adhocFilter.comparator,
      ),
    );

  const handleSubjectChange = (subject: string) => {
    onSubjectChange(subject, cachedOperatorComparatorMap);
  };

  const subjectSelectProps = {
    ariaLabel: t('Select subject'),
    value: subject ?? undefined,
    onChange: handleSubjectChange,
    notFoundContent: t(
      "No such column found.  The column may not exist or it's a sensitive column keyword. To filter on a metric, try the Custom SQL tab.",
    ),
    autoFocus: !subject,
    placeholder: '',
  };

  subjectSelectProps.placeholder =
    props.adhocFilter.clause === Clauses.Where
      ? t('%s column(s)', columns.length)
      : t('To filter on a metric, use Custom SQL tab.');

  // const sensitiveColumns = props.datasource?.sensitive_columns || [];
  columns = props.options.filter(
    option =>
      'column_name' in option &&
      option.column_name /* && !sensitiveColumns.includes(option?.column_name?.toLowerCase()) */,
  );

  const operatorSelectProps = {
    placeholder: t(
      '%s operator(s)',
      (props.operators ?? OPERATORS_OPTIONS).filter(op =>
        isOperatorRelevantWrapper(op, subject),
      ).length,
    ),
    value: operatorId,
    onChange: (value: Operators) =>
      onOperatorChange(
        value,
        cachedOperatorComparatorMap,
        setCachedOperatorComparatorMap,
      ),
    autoFocus: !!subjectSelectProps.value && !operator,
    ariaLabel: t('Select operator'),
  };

  const showNotification = (type: string, msg: string, desc: string) => {
    notification[type]({ message: msg, description: desc });
  };

  const logScanApiDuration = (startTime: number, endpoint: string) => {
    NezhaTelemetry.trackEngagementEvent(analytics, {
      scenario: parseScenarioFromUri(getPathname()),
      operation: NEZHA_OPERATION.API,
      customProperties: {
        apiUri: endpoint,
        responseTime: (new Date().getTime() - startTime).toString(),
      },
    });
  };

  // fetch multi complete value
  const fetchMultiCompleteValue = (comparator: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    const { datasource } = props;
    const col = props.adhocFilter.subject;
    const isOperatorValid =
      props.adhocFilter.clause === Clauses.Having ||
      props.adhocFilter.clause === Clauses.Where;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource && isOperatorValid) {
      const substr = comparator;
      const isCaseSensitive = 0;
      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');

      cacheExistCheck(
        datasource.type, // DatasourceType
        datasource.id, // datasourceId
        unwrappedCol, // unwrappedCol
        () => fetchId !== fetchRef.current, // returnEarlyFn
        (json: JsonObject) => {
          // beforeStateCheckFn
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }
        },
        (
          json: JsonObject, // getAutocompleteCacheStateHandler
        ) => getAutocompleteCacheState(json),
        (state: string) => {
          // stateServiceErrorHandler
          setAutocompleteLoading(false);
          showNotification('error', 'This is error message', state);
        },
        (state: string) => {
          // stateServiceNotReadyHandler
          setAutocompleteLoading(false);
          showNotification('warning', 'This is warning message', state);
        },
        () => {
          // stateColumnEnabledHandler
          const st = new Date().getTime();
          const endpoint = setAutocompleteUrl(
            substr,
            datasource.type,
            datasource.id,
            unwrappedCol,
            isCaseSensitive,
          );
          SupersetClient.get({
            endpoint,
          })
            .then(({ json }) => {
              if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
              }

              logScanApiDuration(st, endpoint);

              setSuggestions(json);
              setAutocompleteLoading(false);
            })
            .catch(e => {
              setSuggestions([]);
              setAutocompleteLoading(false);
            });
        },
        () => {
          // stateColumnDisabledHandler
          setAutocompleteLoading(false);
        },
        () => {
          // stateTableDisabledHandler
          setAutocompleteLoading(false);
        },
        () => {
          // stateOtherDefaultHandler
          setAutocompleteLoading(false);
        },
        (e: any) => {
          // exceptionHandler
          setAutocompleteLoading(false);
        },
      );
    }
  };

  const debounceFetchMultiCompleteValue = debounce(
    fetchMultiCompleteValue,
    debounceDelay,
  );

  useEffect(
    () => () => debounceFetchMultiCompleteValue.cancel(),
    [debounceFetchMultiCompleteValue],
  );

  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const focusMultSelectComparator = (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    console.log(event);
    const { datasource } = props;
    const col = props.adhocFilter.subject;

    const controller = new AbortController();
    const { signal } = controller;

    const substr = event.target.value;
    const isCaseSensitive = 0;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource) {
      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');

      cacheExistCheck(
        datasource.type, // DatasourceType
        datasource.id, // datasourceId
        unwrappedCol, // unwrappedCol
        () => fetchId !== fetchRef.current, // returnEarlyFn
        (json: JsonObject) => {
          // beforeStateCheckFn
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }
        },
        (
          json: JsonObject, // getAutocompleteCacheStateHandler
        ) => getAutocompleteCacheState(json),
        (state: string) => {
          // stateServiceErrorHandler
          setAutocompleteLoading(false);
          showNotification('error', 'This is error message', state);
        },
        (state: string) => {
          // stateServiceNotReadyHandler
          setAutocompleteLoading(false);
          showNotification('warning', 'This is warning message', state);
        },
        () => {
          // stateColumnEnabledHandler
          const st = new Date().getTime();
          const endpoint = setAutocompleteUrl(
            substr,
            datasource.type,
            datasource.id,
            unwrappedCol,
            isCaseSensitive,
          );
          SupersetClient.get({
            signal,
            endpoint,
          })
            .then(({ json }) => {
              if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
              }

              logScanApiDuration(st, endpoint);

              setSuggestions(json);
              setFirstFocusInputComparator(true);
              setAutocompleteLoading(false);
            })
            .catch(e => {
              setAutocompleteLoading(false);
            });
        },
        () => {
          // stateColumnDisabledHandler
          setDebounceDelay(defaultDelay);
          setSuggestions([]);
          setAutocompleteLoading(false);
        },
        () => {
          // stateTableDisabledHandler
          setDebounceDelay(defaultDelay);
          setSuggestions([]);
          setAutocompleteLoading(false);
        },
        () => {
          // stateOtherDefaultHandler
          setDebounceDelay(defaultDelay);
          setSuggestions([]);
          setAutocompleteLoading(false);
        },
        () => {
          // exceptionHandler
          setAutocompleteLoading(false);
        },
      );
    }
  };

  const handleOneLineInputComparator = (
    comparator: string,
    subject: string,
  ) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    // onComparatorChange(comparator);

    const { datasource } = props;
    const col = subject;
    const isOperatorValid =
      props.adhocFilter.clause === Clauses.Having ||
      props.adhocFilter.clause === Clauses.Where;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource && isOperatorValid) {
      const substr = comparator;
      const isCaseSensitive = 0;

      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');

      cacheExistCheck(
        datasource.type, // DatasourceType
        datasource.id, // datasourceId
        unwrappedCol, // unwrappedCol
        () => fetchId !== fetchRef.current, // returnEarlyFn
        (json: JsonObject) => {
          // beforeStateCheckFn
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }
        },
        (
          json: JsonObject, // getAutocompleteCacheStateHandler
        ) => getAutocompleteCacheState(json),
        (state: string) => {
          // stateServiceErrorHandler
          setAutocompleteLoading(false);
          showNotification('error', 'This is error message', state);
        },
        (state: string) => {
          // stateServiceNotReadyHandler
          setAutocompleteLoading(false);
          showNotification('warning', 'This is warning message', state);
        },
        () => {
          // stateColumnEnabledHandler
          const st = new Date().getTime();
          const endpoint = setAutocompleteUrl(
            substr,
            datasource.type,
            datasource.id,
            unwrappedCol,
            isCaseSensitive,
          );
          SupersetClient.get({
            endpoint,
          })
            .then(({ json }) => {
              if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
              }

              logScanApiDuration(st, endpoint);

              setSuggestions(json);
              setAutocompleteLoading(false);
            })
            .catch(() => {
              setSuggestions([]);
              setAutocompleteLoading(false);
            });
        },
        () => {
          // stateColumnDisabledHandler
          setAutocompleteLoading(false);
        },
        () => {
          // stateTableDisabledHandler
          setAutocompleteLoading(false);
        },
        () => {
          // stateOtherDefaultHandler
          setAutocompleteLoading(false);
        },
        () => {
          // exceptionHandler
          setAutocompleteLoading(false);
        },
      );
    }
  };

  const debounceHandleOneLineInputComparator = debounce(
    handleOneLineInputComparator,
    debounceDelay,
  );
  const debouncedOneLineInputSearch = useCallback(
    debounceHandleOneLineInputComparator,
    [],
  );

  useEffect(
    () => () => debounceHandleOneLineInputComparator.cancel(),
    [debounceHandleOneLineInputComparator],
  );

  const handleOneLineInputSearch = (value: string) => {
    const { subject } = props.adhocFilter;
    onComparatorChange(
      value,
      cachedOperatorComparatorMap,
      setCachedOperatorComparatorMap,
    );
    debouncedOneLineInputSearch(value, subject); // Invoke the debounced function
  };

  const focusInputComparator = (event: React.FocusEvent<HTMLInputElement>) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setSuggestions([]);

    const { datasource } = props;
    const col = props.adhocFilter.subject;

    const controller = new AbortController();
    const { signal } = controller;

    const substr = event.target.value;
    const isCaseSensitive = 0;

    // if disabed cache, return directly
    if (props.disableFetchFromCache) {
      return;
    }

    if (!!col && !!datasource) {
      setAutocompleteLoading(true);
      const unwrappedCol = col.trim().replace(/^"|"$/g, '');

      cacheExistCheck(
        datasource.type, // DatasourceType
        datasource.id, // datasourceId
        unwrappedCol, // unwrappedCol
        () => fetchId !== fetchRef.current, // returnEarlyFn,
        (json: JsonObject) => {
          // beforeStateCheckFn
          const { card } = json;
          if (card) {
            setDebounceDelay(buildDebounceDelay(card));
          }
        },
        (
          json: JsonObject, // getAutocompleteCacheStateHandler
        ) => getAutocompleteCacheState(json),
        (state: string) => {
          // stateServiceErrorHandler
          setAutocompleteLoading(false);
          showNotification('error', 'This is error message', state);
        },
        (state: string) => {
          // stateServiceNotReadyHandler
          setAutocompleteLoading(false);
          showNotification('warning', 'This is warning message', state);
        },
        () => {
          // stateColumnEnabledHandler
          const st = new Date().getTime();
          const endpoint = setAutocompleteUrl(
            substr,
            datasource.type,
            datasource.id,
            unwrappedCol,
            isCaseSensitive,
          );
          SupersetClient.get({
            signal,
            endpoint,
          })
            .then(({ json }) => {
              if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
              }

              logScanApiDuration(st, endpoint);

              setSuggestions(json);
              setFirstFocusInputComparator(true);
              setAutocompleteLoading(false);
            })
            .catch(e => {
              setAutocompleteLoading(false);
            });
        },
        () => {
          // stateColumnDisabledHandler
          setSuggestions([]);
          setAutocompleteLoading(false);
        },
        () => {
          // stateTableDisabledHandler
          setSuggestions([]);
          setAutocompleteLoading(false);
        },
        () => {
          // stateOtherDefaultHandler
          setSuggestions([]);
          setAutocompleteLoading(false);
        },
        () => {
          // exceptionHandler
          setAutocompleteLoading(false);
        },
      );
    }
  };

  const isDebug = false;
  if (isDebug !== undefined && isDebug !== false) {
    console.log(DISABLE_INPUT_OPERATORS);
    console.log(clearOperator);
    // console.log(comparator);
    console.log(firstFocusInputComparator);
    console.log(fetchAdvancedDataTypeValueCallback);
    console.log(fetchSubjectAdvancedDataType);
  }

  const noValueOperators = [
    'IS_EMPTY',
    'IS_NOT_EMPTY',
    'IS_FALSE',
    'IS_TRUE',
    'IS_NULL',
    'IS_NOT_NULL',
    // Hide input box for 'Is empty' and 'Is not empty' operators.
    'IS_BOOLEAN_EMPTY',
    'IS_BOOLEAN_NOT_EMPTY',
  ];

  const isNoValueOperator = () =>
    noValueOperators.some(op => op === operatorSelectProps.value);
  const isMultiSelectOperator = () =>
    operatorSelectProps.value === 'IN' ||
    operatorSelectProps.value === 'NOT_IN';
  const multiSelectValueConvert = (comparator: any | any[]) => {
    if (isArray(comparator)) {
      return comparator.map(value => ({ value, label: value }));
    }
    return [{ value: comparator, label: comparator }];
  };
  // another name for columns, just for following previous naming.
  const subjectComponent = (
    <Select
      css={(theme: SupersetTheme) => ({
        marginTop: theme.gridUnit * 4,
        marginBottom: theme.gridUnit * 4,
      })}
      data-test="select-element"
      options={columns.map(column => ({
        value:
          ('column_name' in column && column.column_name) ||
          ('optionName' in column && column.optionName) ||
          '',
        label:
          ('saved_metric_name' in column && column.saved_metric_name) ||
          ('column_name' in column && column.column_name) ||
          ('label' in column && column.label),
        key:
          ('id' in column && column.id) ||
          ('optionName' in column && column.optionName) ||
          undefined,
        customLabel: renderSubjectOptionLabel(column),
      }))}
      {...subjectSelectProps}
    />
  );

  const operatorsAndOperandComponent = (
    <>
      <Select
        css={(theme: SupersetTheme) => ({ marginBottom: theme.gridUnit * 4 })}
        options={(props.operators ?? OPERATORS_OPTIONS)
          .filter(op => isOperatorRelevantWrapper(op, subject))
          .map((option, index) => ({
            value: option,
            label: OPERATOR_ENUM_TO_OPERATOR_TYPE[option].display,
            key: option,
            order: index,
          }))}
        {...operatorSelectProps}
      />
      {isNoValueOperator() ? null : isMultiSelectOperator() ? (
        <Select
          loading={autocompleteLoading}
          labelInValue
          css={(theme: SupersetTheme) => ({ marginBottom: theme.gridUnit * 4 })}
          options={suggestions.map((suggestion: string) => ({
            value: suggestion,
            label: String(suggestion),
          }))}
          maxTagCount={7}
          mode="multiple"
          value={
            props.adhocFilter.comparator
              ? multiSelectValueConvert(props.adhocFilter.comparator)
              : []
          }
          onChange={newValue => {
            if (
              newValue !== null ||
              newValue !== undefined ||
              (Array.isArray(newValue) && (newValue as any[]).length !== 0)
            ) {
              onComparatorChange(
                (newValue as InValue[]).map(value => value.value),
                cachedOperatorComparatorMap,
                setCachedOperatorComparatorMap,
              );
            } else {
              onComparatorChange(
                [],
                cachedOperatorComparatorMap,
                setCachedOperatorComparatorMap,
              );
            }
          }}
          onFocus={focusMultSelectComparator}
          ariaLabel={t('Input value')}
          // filterOption
          allowNewOptions
          onSearch={debounceFetchMultiCompleteValue}
        />
      ) : (
        <AutoComplete
          style={{ width: '100%' }}
          options={suggestions.map((suggestion: string) => ({
            value: suggestion,
            label: String(suggestion),
          }))}
          // placeholder="Filter value"
          onChange={handleOneLineInputSearch}
          onFocus={focusInputComparator}
          value={
            props.adhocFilter.comparator ? props.adhocFilter.comparator : ''
          }
        >
          <AntdInput.Search
            size="middle"
            placeholder="Filter value"
            loading={autocompleteLoading}
          />
        </AutoComplete>
      )}
    </>
  );
  return (
    <>
      {subjectComponent}
      {operatorsAndOperandComponent}
    </>
  );
};

export default AdhocFilterEditPopoverSimpleTabContent;

import { Operators } from 'src/explore/constants';
import { OptionSortType } from 'src/explore/types';
import { DndItemType } from '../../DndItemType';
import AdhocFilter from '../FilterControl/AdhocFilter';
import { OptionControlLabel } from '../OptionControls';
import { IAdvancedMetricFilter } from './AdvancedMetricFilter.type';
import AdvancedMetricFilterGroup from './AdvancedMetricFilterGroup';

export interface IAdvancedFilterProps {
  selectedKey?: string;
  filter: IAdvancedMetricFilter;
  operators?: Operators[];
  sections: string[];
  onSelectFilter: (selectedFilter?: IAdvancedMetricFilter) => void;
  onSelectGroup: (selectedFilterGroup: AdvancedMetricFilterGroup) => void;
  onRemove: (removedFilter?: IAdvancedMetricFilter) => void;
  datasource: object;
  onFilterEdit: (editedFilter: AdhocFilter) => void;
  options: OptionSortType[];
  disabled?: boolean;
  viz_type?: string;
}

export default function AdvancedFilter(
  props: IAdvancedFilterProps,
): JSX.Element {
  const noop = () => {};
  return (
    <div
      className={`node ${props.filter.container.key} ${props.filter.key}`}
      onClick={() => {
        props.onSelectFilter(props.filter);
        props.onSelectGroup(props.filter.container);
      }}
      style={
        props.filter.key === props.selectedKey
          ? { fontWeight: 'bold', marginBottom: 2 }
          : { marginBottom: 2 }
      }
      role="button"
      tabIndex={0}
    >
      <OptionControlLabel
        label={props.filter.adhocFilter.getDefaultLabel()}
        tooltipTitle={props.filter.adhocFilter.getTooltipTitle()}
        onRemove={() => {
          props.onSelectFilter(undefined);
          props.onRemove(props.filter);
        }}
        type={DndItemType.FilterOption}
        isExtra={props.filter.adhocFilter.isExtra}
        onMoveLabel={noop}
        onDropLabel={noop}
        index={0}
        sections={props.sections}
        operators={props.operators}
        adhocFilter={props.filter.adhocFilter}
        datasource={props.datasource}
        options={props.options}
        onFilterEdit={props.onFilterEdit}
        withCaret={!props.disabled}
        disabled={props.disabled}
        viz_type={props.viz_type}
        isFromAdvancedFilter
      />
    </div>
  );
}

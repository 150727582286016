import { isValidElement } from 'react';

export function getNestedText(children: React.ReactNode): string {
  if (typeof children === 'string') {
    return children;
  }
  if (Array.isArray(children)) {
    return children.map(getNestedText).join('');
  }
  if (isValidElement(children)) {
    return getNestedText(children.props.children);
  }
  return '';
}

export function getHtmlTextFromHtmlElement(target: EventTarget): string {
  if (target instanceof HTMLElement) {
    return target.innerText || target.textContent || '';
  }
  return '';
}

export function getNormalizedStringValue(value: string | string[]): string {
  if (Array.isArray(value)) {
    return value.join(',');
  }
  return value;
}

import { useEffect, useRef, useState } from 'react';
import { t } from '@superset-ui/core';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { SteppedLineTo, Orientation } from 'react-lineto';
import { theme } from 'src/preamble';
import { Operators } from 'src/explore/constants';
import AdvancedFilter from './AdvancedFilter';
import { IAdvancedMetricFilter } from './AdvancedMetricFilter.type';
import AdvancedMetricFilterGroup from './AdvancedMetricFilterGroup';
import AdhocFilter from '../FilterControl/AdhocFilter';

export interface IAdvancedFilteGroupProps {
  operators?: Operators[];
  sections: string[];
  filterGroup: AdvancedMetricFilterGroup;
  selectedFilter?: IAdvancedMetricFilter;
  isFromMetrics?: boolean;
  onSelectFilter: (filter?: IAdvancedMetricFilter) => void;
  onSelectGroup: (filterGroup: AdvancedMetricFilterGroup) => void;
  triggerChange: () => void;
  onRemove: (removedFilter?: IAdvancedMetricFilter) => void;
  datasource: object;
  onFilterEdit: (editedFilter: AdhocFilter) => void;
  options: any[];
  disabled?: boolean;
  viz_type?: string;
}

export default function AdvancedFilterGroup(
  props: IAdvancedFilteGroupProps,
): JSX.Element {
  const elementRef = useRef(null);

  const steppedLineStyle = {
    delay: true,
    borderColor: theme.colors.primary.light1,
    borderStyle: 'solid',
    borderWidth: 1,
  };

  const [update, setUpdate] = useState(0);

  useEffect(() => {
    const controlSectionsElement = document.getElementById('controlSections');
    if (elementRef.current && controlSectionsElement) {
      const groupElement: HTMLElement = elementRef.current;
      // get element viewport position
      const groupRect = groupElement.getBoundingClientRect();
      // get element relative position from viewport top
      const groupDistanceToTop = groupRect.top;
      // scroll offset
      const groupScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const groupDistanceToPageTop = groupDistanceToTop + groupScrollTop;

      const controlSectionsRect =
        controlSectionsElement.getBoundingClientRect();
      const controlSectionsDistanceToTop = controlSectionsRect.top;
      const opacity = (function () {
        const distance = groupDistanceToPageTop - controlSectionsDistanceToTop;
        if (distance > 0) {
          if (distance > 100) {
            return 1;
          }
          return distance / 100.0;
        }
        return 0;
      })();

      // TODO: need to find a better way to update the lines
      const timerId = setTimeout(() => {
        setUpdate(Math.floor(update + 1) + opacity * 0.1);
      }, 100); // Change it to a larger number to reduce the frequency of updates
      return () => clearTimeout(timerId);
    }
    return undefined;
  });

  const renderSteppedLineTo = (
    from: string,
    to: string,
    fromAnchor: string,
    toAnchor: string,
    orientation: Orientation | undefined,
    zIndex: number | undefined,
    update: number,
    steppedLineStyle: {},
  ) => {
    const opacity = (update % 1) * 10;
    const suffix = Math.floor(update);
    if (zIndex !== undefined) {
      return (
        <SteppedLineTo
          css={{ opacity }}
          key={`${fromAnchor}_${toAnchor}_${suffix}`}
          from={from}
          to={to}
          fromAnchor={fromAnchor}
          toAnchor={toAnchor}
          orientation={orientation}
          zIndex={zIndex}
          {...steppedLineStyle}
        />
      );
    }
    return (
      <SteppedLineTo
        css={{ opacity }}
        key={`${fromAnchor}_${toAnchor}_${suffix}`}
        from={from}
        to={to}
        fromAnchor={fromAnchor}
        toAnchor={toAnchor}
        orientation={orientation}
        {...steppedLineStyle}
      />
    );
  };

  function renderGroup(curFilterGroup: AdvancedMetricFilterGroup): JSX.Element {
    const button: JSX.Element = (
      <>
        <Button
          className={`operator-btn ${curFilterGroup.key.toString()}`}
          onClick={() => {
            curFilterGroup.switchGroupOperator();
            props.onSelectFilter(undefined);
            props.onSelectGroup(curFilterGroup);
            props.triggerChange();
          }}
          size="small"
          disabled={props.disabled}
          style={{
            visibility:
              curFilterGroup.filters.length + curFilterGroup.groups.length > 1
                ? 'visible'
                : 'hidden',
            zIndex: 1,
          }}
        >
          {t(curFilterGroup.operator)}
        </Button>
        {renderSteppedLineTo(
          `operator-btn ${curFilterGroup.key.toString()}`,
          `operator-btn ${curFilterGroup.key.toString()}`,
          'left',
          'right',
          'h',
          curFilterGroup.parent === undefined ||
            curFilterGroup.parent.filters.length === 0
            ? -1
            : 0,
          update,
          steppedLineStyle,
        )}
      </>
    );

    const filterElements: JSX.Element[] = curFilterGroup.filters.map(filter => (
      <>
        {curFilterGroup.filters.length + curFilterGroup.groups.length > 1 ||
        (filter.container.parent !== undefined &&
          filter.container.parent.filters.length > 0)
          ? renderSteppedLineTo(
              `operator-btn ${curFilterGroup.key.toString()}`,
              `node ${curFilterGroup.key.toString()} ${filter.key.toString()}`,
              'right',
              'left',
              'h',
              0,
              update,
              steppedLineStyle,
            )
          : null}
        <AdvancedFilter
          selectedKey={props.selectedFilter?.key}
          filter={filter}
          onSelectFilter={props.onSelectFilter}
          onSelectGroup={props.onSelectGroup}
          onRemove={props.onRemove}
          datasource={props.datasource}
          onFilterEdit={props.onFilterEdit}
          options={props.options}
          operators={props.operators}
          sections={props.sections}
          disabled={props.disabled}
          viz_type={props.viz_type}
        />
      </>
    ));
    const groupElements: JSX.Element[] | undefined =
      curFilterGroup.groups.length !== 0
        ? curFilterGroup.groups.map(group => (
            <>
              {curFilterGroup.filters.length > 0
                ? renderSteppedLineTo(
                    `operator-btn ${curFilterGroup.key.toString()}`,
                    `operator-btn ${group.key.toString()}`,
                    'right',
                    'left',
                    'h',
                    undefined,
                    update,
                    steppedLineStyle,
                  )
                : null}
              {renderGroup(group)}
            </>
          ))
        : undefined;

    return (
      <Row style={{ width: '100%' }} ref={elementRef}>
        <Col xs={4}>{button}</Col>
        <Col xs={20}>
          {filterElements}
          {groupElements}
        </Col>
      </Row>
    );
  }

  return <>{renderGroup(props.filterGroup)}</>;
}
